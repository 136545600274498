<template>
  <div class="main-privacy">
    <div class="container-main">
      <div class="container-up">
        <img :src="'/images/mundouniversitariologo.png'" class="imglogo" />
      </div>
      <div class="container-title">
        <h1 class="txt-title">Aviso De Privacidad </h1>
      </div>
      &nbsp; &nbsp;
      <div class="container-description">
        <p>
          El responsable de los datos personales que usted proporciona es Mundo
          Universitario, S.A DE C.V en lo sucesivo MUNDO UNIVERSITARIO, con
          domicilio en Avenida Lomas Verdes 115, Lomas Verdes 1ª sección,
          Naucalpan de Juárez, Estado de México, México. 53120 MUNDO
          UNIVERSITARIO como parte de su comunidad universitaria recabará y
          tratará datos personales de identificación, datos personales de
          contacto, datos personales laborales, datos personales sobre
          características físicas, datos personales académicos, entre otros.<br />
          <br />
          Los titulares de los datos personales al momento de llenar y suscribir
          su respectiva solicitud de ingreso fueron informados de las
          condiciones de tratamiento de los datos de carácter personal y de que,
          por el hecho de suscribir dicha solicitud, emitían su aceptación y
          consentimiento al tratamiento de estos por parte de MUNDO
          UNIVERSITARIO, en la forma y para las finalidades indicadas en el
          presente Aviso de Protección de Datos Personales.<br />
          <br />
          De igual forma, se entiende que el titular consiente tácitamente el
          tratamiento de sus datos, al haberse puesto a su disposición el
          presente aviso de privacidad y no haber manifestado su oposición. El
          tratamiento de sus datos personales sensibles tiene como propósito que
          MUNDO UNIVERSITARIO cumpla con las obligaciones derivadas de la
          relación jurídica que tenemos con usted. Nos comprometemos a que los
          mismos serán tratados bajo estrictas medidas de seguridad, siempre
          garantizando su confidencialidad. El tratamiento que MUNDO
          UNIVERSITARIO dará a sus datos personales a fin de cumplir con
          nuestras obligaciones derivadas de nuestra relación jurídica:<br />
          <br />
          – Para enviarle información de su suscripción a nuestra comunidad de
          universitarios;<br />
          – Para verificar que se trate de una persona real y que la información
          que nos ha proporcionado es verídica;<br />
          – Para la difusión de información pertinente a novedades en la
          comunidad;<br />
          – Para efectos de vinculación y enlace con empresas públicas y/o
          privadas en relación con los programas de becas;<br />
          – Para envío de promociones, descuentos o dinámicas de interés de las
          tiendas universitarios o artículos universitarios;<br />
          – Para la aplicación de encuestas y evaluaciones para mejorar la
          calidad de los servicios que ofrecemos;<br />
          – Para enviarle publicidad y comunicaciones con fines de
          mercadotecnia, tele-marketing o campañas financieras;<br />
          – Para la aplicación de encuestas, evaluaciones, referencias e
          información necesaria con respecto de MUNDO UNIVERSITARIO, lo anterior
          para la valoración de las empresas con las que tenemos convenios en
          relación con los programas de becas.<br />
          – Para la administración del acceso electrónico a los sistemas e
          infraestructura tecnológica de MUNDOUNIVERSITARIO;<br />
          – Para contactar a sus familiares o terceros de contacto en caso de
          una emergencia.<br />
          – Para realizar reportes semanales, mensuales y anuales;<br />
          – Para las gestiones de facturación y procesos de cobranza
          correspondientes;<br />
          <br />
          Por otra parte, hacemos de su conocimiento que sus datos podrán ser
          transferidos a entidades del mismo grupo de interés de la empresa,
          nacionales o extranjeras, con el objetivo general de cumplir con las
          finalidades para las cuales ha proporcionado sus datos. Propósitos
          específicos: Para la vinculación con distintas personas físicas y
          morales para el ingreso de estudiantes a los Programas de Becarios que
          desean poner en prácticas sus conocimientos académicos.<br />
          <br />
          En este sentido su información podrá ser compartida con las empresas
          que a continuación se mencionan: Empresas con quien MUNDOUNIVERSITARIO
          participa en el Programa de Becarios o tiene celebrado contrato o
          convenio de colaboración educativa. Si usted no desea que
          MUNDOUNIVERSITARIO transfiera sus datos personales le pedimos que
          envíe un correo electrónico a la dirección
          datospersonales@mundouniversitario.com indicándonos su nombre
          completo, y para qué finalidad no desea que sus datos sean
          transferidos. MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO,
          RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN (ARCO) DE LOS DATOS
          PERSONALES.<br />
          <br />
          Los titulares de los datos personales pueden corregir la información
          que es inexacta o incompleta. MUNDOUNIVERSITARIO responderá a la
          petición. Asimismo, pueden ejercer su derecho al acceso,
          rectificación, cancelación u oposición (ARCO) de sus datos personales
          por escrito dirigido al domicilio de MUNDOUNIVERSITARIO.<br />
          <br />
          Su solicitud deberá contener:<br />
          I. El nombre del Titular y domicilio u otro medio para comunicarle la
          respuesta a su solicitud;<br />
          II. Los documentos que acrediten la identidad o, en su caso, la
          representación legal del Titular;<br />
          III. La descripción clara y precisa de los datos personales respecto
          de los que se busca ejercer alguno de los derechos antes mencionados,
          y<br />
          IV. Cualquier otro elemento o documento que facilite la localización
          de los datos personales.<br />
          <br />
          Por otra parte, el titular de los datos personales podrá revocar el
          consentimiento que han otorgado a MUNDO UNIVERSITARIO para el
          tratamiento de sus datos personales que no sean indispensables para el
          cumplimiento de sus obligaciones, a fin de que MUNDO UNIVERSITARIO
          deje de hacer uso de los mismos. Para ello, es necesario que el
          titular presente su petición en los términos del formato antes<br />
          mencionado.<br />
          <br />
          Para el caso de que la solicitud sea presentada por una persona
          distinta al titular, deberá presentar carta poder expedida por el
          titular de los datos personales, suscrita ante dos testigos y anexando
          copia simple de la identificación oficial del titular y del
          representante legal.<br />
          <br />
          Limitación y/o Divulgación de sus datos<br />
          <br />
          Usted podrá limitar el uso o divulgación de sus datos personales, nos
          puede comunicar lo anterior al correo
          datospersonales@mundouniversitario.com indicándonos su nombre completo
          y que dato desea que no sean divulgados. En caso de que su solicitud
          sea procedente se le notificará vía electrónica dicha
          circunstancia.<br />
          <br />
          Uso de Cookies<br />
          <br />
          Le informamos que en algunas de nuestras páginas de internet
          utilizamos cookies y otras tecnologías, a través de las cuales es
          posible monitorear su comportamiento como usuario de internet, así
          como brindarle un mejor servicio y experiencia al navegar en nuestra
          página. Los datos personales que se pueden obtener a través del uso de
          estas tecnologías son los siguientes: Identificadores, nombre usuario
          y contraseñas de una sesión, región en la que se encuentra, tipo de
          navegador, tipo de sistema operativo, fecha y hora del inicio y final
          de una sesión, páginas web visitadas, búsquedas realizadas y
          publicidad revisada. Estas tecnologías podrán deshabilitarse siguiendo
          los procedimientos del navegador de internet que utiliza.<br />
          <br />
          BAJA DE DATOS PERSONALES<br />
          <br />
          MUNDO UNIVERSITARIO queda expresamente facultado para dar de baja en
          forma unilateral, sin necesidad de requerir el consentimiento previo
          de los titulares de los datos personales cuando haya concluido, por
          cualquier motivo, la relación.<br />
          <br />
          Modificación al Aviso de Privacidad<br />
          <br />
          MUNDOUNIVERSITARIO le notificará de cualquier cambio a su aviso de
          privacidad a través de la liga www.mundounviersitario.mx y
          posteriormente accediendo a su aviso de privacidad.
        </p>
      </div>
    </div>
  </div>
</template>

<style land="scss" scoped>
@import "../styles/privacy.scss";
</style>
